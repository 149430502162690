.dashboard {
    width: 100vw;
    display: flex;
}

.dashboard__container {
    display: flex;
    flex-direction: column;
    background-color: #dcdcdc;
    padding: 30px;
}

.dashboard__btn {
    padding: 10px;
    font-size: 18px;
    margin-top: 10px;
    border: none;
    color: white;
    background-color: black;
}

.dashboard div {
    margin-top: 7px;
}

.fileCtrl {
    border: 1px solid;
    padding: 5px;
    border-radius: 10px;
}

/*input[type=file]::file-selector-button {*/
/*margin-right: 20px;*/
/*border: none;*/
/*background: #99c793;*/
/*padding: 10px 20px;*/
/*border-radius: 10px;*/
/*color: #fff;*/
/*cursor: pointer;*/
/*content: "Browse";*/
/*width: 150px;*/
/*transition: background .2s ease-in-out;*/
/*}*/
input[type=file]::file-selector-button {

    padding: 8px 20px;




    border-radius: 33px;
    border: 1.2px solid #202020;
    color: #202020;
    background: #ffff;
    font-family: Raleway !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;








    cursor: pointer;
    content: "Browse";
    width: 150px;
    transition: background .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
    background: #f1eeee
}

.inputFileCtrl {
    width: 100%;
}

.fileCtrlMultiple {
    border: 1px solid #C1C1C1;
    padding: 5px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.btnActions {
    display: flex;
    justify-content: space-around;
    padding: 24px 32px;


    text-align: center;
    position: sticky;
    z-index:9999;
    bottom: 0;
    background: #ffff;

}
.btnUpdateActions {
    display: flex;
    justify-content: space-around;
    padding: 24px 0px;


    text-align: center;
    position: sticky;
    z-index:9999;
    bottom: 0;
    background: #ffff;

}
.bank-card-layout{
    display: flex;
    /*width: 440px;*/
    height: 45px;
    padding: 4px 12px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background: #F8F8F8;
    margin-top:24px;
}

.btnSubmit {
    width: 100px;
    padding: 6px;
    margin-right: 10px !important;
}

.btnCancel {
    width: 100px;
    padding: 6px;
    margin-right: 10px;
    background: #f6f6f6 !important
}

.priceBox {
    display: flex;
    width: 65px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 22px;
    background: #FFE5D8;
}

.freeBox {
    display: flex;
    width: 65px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 22px;
    background: #CFEFDB;
}