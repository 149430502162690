.pos_header {
    color: #242424;
    font-family: "Nunito Sans" !important;
    font-size: 24px;
    padding-top: 6px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 83.333% */
}

.pos-instructions__card-layout {
    margin-top: 46px;
    display: flex;
    width: 416px;
    padding: 24px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 20px;
    background: #F7F7F7;
}

.pos-instructions-card__header {
    padding-left: 12px;
    color: #202020;
    font-family: Raleway !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
}

.find-user__container {
    margin-top: 48px;
}

.pos-instructions-card__hr {
    margin-top: calc(17px - 10px);
    margin-bottom: calc(18px - 10px);
    background: #E2E2E2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 17px;
    align-self: stretch;
}

.pos-instructions-card__index {
    background: #FFAD83;
    width: 24px;
    border-radius: 100%;
    height: 24px;
    display: flex;
    justify-content: center;

    color: #202020;
    text-align: center;
    font-family: "Open Sans" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
}

.pos-instructions-card_steps {
    color: #202020;
    font-family: Raleway !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    padding-left: 12px;
}

.pos-instructions-card_info {
    padding-left: 12px;
    color: #006D2C;
    font-family: Raleway !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
}

.pos-instructions-card_Warning {
    padding-left: 12px;
    color: #C40000;
    font-family: Raleway !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
}

.pos-field__label {
    color: #202020;
    font-family: Raleway !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
}

.pos-dropdown__field {
    border-radius: 34px !important;
    border: 1px solid #C1C1C1 !important;
    display: flex !important;
    padding: 8px 6px;
    width: 316px;

}

.pos-input__field {

    border-radius: 34px !important;
    border: 1px solid #C1C1C1 !important;
    display: flex !important;
    padding: 12px 18px !important;
    width: 273px;
}

.pos-verify__btn {
    border-radius: 33px;
    background: #FF9058;
    display: flex;
    padding: 12px 13px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    width: 127px;
    height: auto;
    border: none;
    color: #202020;
    font-family: Raleway !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    margin-left: 18px
}

.pos-saveorder__btn {
    border-radius: 33px;
    background: #FF9058;
    display: flex;
    padding: 12px 13px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    width: 127px;
    height: auto;
    border: none;
    color: #202020;
    font-family: Raleway !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-left: 1px
}

.pos-find-number__btn {
    border-radius: 33px;
    background: #ffff;
    display: flex;
    padding: 12px 13px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    width: 145px;
    height: auto;

    font-family: Raleway !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    margin-left: 16px;
    border-radius: 33px;
    border: 1.5px solid #202020;

}

.pos-search-member_card {
    padding-left: 24px
}

.pos-select-product_container {
    margin-top: 48px;
    margin-bottom: 24px;
    dispaly: flex
}

.pos-select-product__header {
    color: #202020;
    font-family: Raleway !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 20px;
    /* 125% */
    text-transform: uppercase;
    padding-left: 12px;
}

.pos-select-product__filter {
    display: flex;
    margin-left: 0px;
    margin-right: 16px;
    padding: 8px 16px;

    align-items: center;
    width: auto;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid #DEDEDE;
}

.pos-select-product__filter.pos-select-product-hover:hover {
    background: #F5F5F5 !important;
}

.primaryHover:hover {
    background: #FF691E !important;
}

.pos-selected-list-table__container {
    width: 705px;
    min-height: 188px;
    align-items: flex-start;
    gap: -1px;
    border-radius: 20px;
    border: 1px solid #E1E1E1;
    background: #FFF;
}

.pos-qnt-increment:hover {
    background: #008736 !important;
}

.pos-qnt-decrement:hover {
    background: #878787 !important;
}

.pos-selected-list-table__header {

    padding-top: 12px;
    padding-bottom: 12px;
    color: #878787;
    font-family: Raleway !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}

.pos-selected-list-table__image {
    padding-left: 16px;
    padding-top: 16px;
}

.selected-filter {
    background: #DEDEDE
}

.pos-selected-list-table__item-label {
    padding-left: 12px;
    color: #202020 !important;
    font-family: "Nunito Sans" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 142.857% */
}

.pos-selected-list-table__add-price {
    height: 20px;
    width: 20px;
    border-radius: 100%;
    color: #ffff
}

.pos-selected-list-table__counts {
    width: 21px;
    height: 20px;
}

.pos-selected-list-table__price {
    color: #202020;
    text-align: center;
    font-family: "Nunito Sans" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 142.857% */
}

.pos-price__label {
    color: #202020;
    text-align: center;
    font-family: "Nunito Sans" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
}

.pos-payment-summary {
    width: 415px;
    /* height: 255px; */
    height: fit-content;
    margin-left: 16px;
    align-items: flex-start;
    gap: -1px;
    border-radius: 20px;
    border: 1px solid #E1E1E1;
    background: #F6F6F6;
}

.pos-payment-summary__header-tag {
    padding-left: 8px;
    padding-top: 12px;
    padding-bottom: 8px;
}

.pos-payment-summary__header {
    padding-left: 0px;
    color: #000;
    font-family: Raleway !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    text-transform: uppercase;
}

.pos-payment-summary__data {
    padding-top: 22px;
    padding-left: 24px;
    padding-right: 24px;
    color: #202020;
    font-family: Raleway !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;

}

.pos-payment-details__typo {
    color: #202020;
    font-family: Raleway !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 125% */
}

.pos-button-typo {
    color: #202020;
    font-family: Raleway !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 20px;
    /* 142.857% */
}

.pos_loading_card__box {
    border-radius: 16px;
    border: 1px solid #F9F9F9;
    overflow: hidden;
    width: 140px;
    height: 213px;
    flex-shrink: 0;
    margin-left: 20px;

}

.pos-payment-summary__input {
    display: flex;
    padding: 8px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: flex-end; */
    gap: 10px;
    border-radius: 29px;
    border: 1px solid #C1C1C1;
    text-align: end;
}

.pos-primary__btn {
    border-radius: 33px !important;
    background: #FF9058 !important;
    color: #202020 !important;
    display: flex;
    padding: 12px 24px 12px 20px !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.pos-secondary__btn {
    border: 1.5px solid #202020 !important;
    border-radius: 33px !important;
    background: #ffffff !important;
    color: #202020 !important;
    display: flex;
    padding: 12px 24px 12px 20px !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .d-flex {
        /* Additional or modified styles for iPad */
        flex-wrap: wrap;
    }

    .pos-search-member_card {
        width: 30%;
        /* Adjust width as needed */
        padding-left: 20px;
        margin-top: 10px
            /* Additional styles for search member card on iPad */
    }

    .d-flex-mix {
        display: flex !important;
    }

    .pos-payment-summary {
        margin-top: 10px;
        margin-left: 0px;
    }

    .reset-and-collect-payment {
        /* width:100vw; */
        display: flex;
        justify-content: end;
    }

    /* Add more styles for other elements as needed */
}

.pos-paybutton__container {
    margin-top: 48px;
    width: 715px;
    min-height: 188px;
    align-items: flex-start;
    gap: -1px;
    border-radius: 20px;
    border: 1px solid #E1E1E1;
    background: #FFF;
    padding: 12px;
}

.pos-opennewtabfont {
    color: #000;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
    padding-left: 8px;
}

/* fluentui tab color */
.f1ksivud::after {
    background-color: #ff9058 !important;
}

.rw4brat:enabled:checked~.fui-Switch__indicator {
    background-color: #ff9058 !important;
}

/* Media query for desktop */
@media only screen and (min-width: 1025px) {

    /* Additional or modified styles for desktop */
    .pos-field__label {
        /* Example: Increase font size for desktop */
        /*font-size: 18px;*/
    }

    /* Add more styles for other elements as needed */
}
.pos-select-product-container {
    max-height: 300px; /* Set a fixed height as needed */
    overflow-y: auto;
}