.login {
    width: 100vw;
    min-height: 100vh;
    background-image: url('./static/images/loginBg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;

}
.login_logo__label{
    font-family: Raleway !important;

    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
 margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login__card{
    height: calc(100vh - 66px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.login_row__container{
    display: flex;
    justify-content: start;
}
.login_row_login__label{
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 24px;
    font-weight: 700;
   margin-left: 10px;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
}
.login_field{
    /*width: calc(100% - 48px) !important;*/
    /*height: 38px !important;*/
    padding: 12px 18px !important;
    gap: 10px !important;
    border-radius: 34px !important;
    border: 1px solid #C1C1C1 !important;

}
.login-fields__container{
    margin-top:40px;

}
.login-password-fields{
    margin-top:24px;
}

.forgot_password_label{
    font-family: 'Raleway', sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    color: #565656;
    text-decoration: none;
    display: flex;
    justify-content: end;
    margin-top:calc(34px - 16px)
}
.login_form__label{
    display: flex;
    margin-bottom: 8px;
    justify-content: start;
    font-family: Raleway !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

}
.login__container {
    display: flex;
    height: 448px;
    border-radius: 24px;
    padding: 48px 40px 48px 40px;
    width: 424px;
    flex-direction: column;
    text-align: center;
    background-color: #FFFFFF;
    /*padding: 30px;*/
}

.login__textBox {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
}

.login__btn {
    gap: 10px;
    border-radius: 33px;
    background: #FF9058 !important;
    color:#202020 !important;
    cursor: pointer;
    margin-top:32px !important;
    height: 44px;
    padding: 12px 13px 12px 13px;
    gap: 10px;
    border-radius: 33px !important;
    display: flex;
    justify-content: center;
}

.login__google {
    background-color: #4285f4;
}