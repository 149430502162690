.reset {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .reset__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #dcdcdc;
    padding: 30px;
  }
  .forgot-password__header{
    margin-top:32px;
    font-family: Nunito Sans !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color : #202020;

  }
  .reset__textBox {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .reset__btn {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: black;
  }
  .reset div {
    margin-top: 7px;
  }

  .forgot_note{
    font-family: Raleway !important;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #565656;


    margin-top:16px;
  }