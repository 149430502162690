/* ProductList.css */

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.parent-container {
  display: flex;
  flex-direction: column;
}

.product-item {
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 16px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  position: relative;
  min-height: 88px !important;
  flex: 1;
  /* Fill remaining height */
}

/*.product-item {*/
/*  box-sizing: border-box;*/
/*  padding: 10px;*/
/*  border: 1px solid #ddd;*/
/*  border-radius: 16px;*/
/*  text-align: center;*/
/*  cursor: pointer;*/
/*  transition: background-color 0.3s ease-in-out;*/
/*  position: relative;*/
/*  min-height: 88px !important;*/
/*}*/
.product_price {
  color: #202020 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  margin-bottom: 12px;
  position: absolute;

  bottom: 0;
}

.product_qty {
  color: #E14B00;
  text-align: right;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 12px;
  position: absolute;
  bottom: 0;
  right: 7px;
}

.product_label {
  color: #202020 !important;
  font-family: "Nunito Sans" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  /* 142.8 57% */
}

.product__price-container {
  border-radius: 0px 0px 12px 12px;
  background: #F9F9F9;
}

.product-item:hover {
  background-color: #FFF8F5;
}

.product-item.selected {
  border: 1px solid #FFBA97 !important;
}

/* Responsive styles */
@media (max-width: 768px) {
  .product-item {
    flex: 1 0 calc(50% - 20px);
    /* On smaller screens, two items per row */
  }
}