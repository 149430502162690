.popupmain {
    width: 286px;
    height: 356px;
    position: relative;
    top: 124px;
    border-radius: 10px;
    border: 1px solid #F4F4F4;
    background: #FFF;
    box-shadow: 1px 1px 4px 0px rgba(89, 89, 89, 0.25);
    padding: 16px;
    overflow-y: auto;
}

.popupSearchbox {
    display: flex;
    width: 283px;
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* position: absolute; */
    left: 52px;
    top: 60px;
    border-radius: 10px;
    border: 1px solid #C1C1C1;
}

.popupMemberList {
    padding-top: 10px;
    max-height: 266px;
    /* Adjust as needed */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.popupInvoiceStatus {
    width: 286px;
    height: 156px;
    position: relative;
    top: 124px;
    border-radius: 10px;
    border: 1px solid #F4F4F4;
    background: #FFF;
    box-shadow: 1px 1px 4px 0px rgba(89, 89, 89, 0.25);
    padding: 16px;
    overflow-y: auto;
}

.popupInvoiceStatusList {
    padding-top: 10px;
    max-height: 266px;
    /* Adjust as needed */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.fillButton {
    width: 88px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 33px;
    background: #FF9058;
    color: #202020;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-left: 10px;
}

.borderButton {
    width: 88px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 33px;
    border: 1px solid #000;
    color: #202020;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
}

.buttonContainer {
    /* Flexbox styles to stick buttons to bottom and align from the right */
    display: flex;
    justify-content: flex-end;
    /* Align items from right */
    position: absolute;
    bottom: 16px;
    /* Stick to bottom */
    right: 16px;
    /* Align from the right */
}

.memberNameDiv {
    margin-left: 24px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid #6B6B6B;
    color: #202020;
    font-family: Raleway;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
    text-transform: uppercase;
    position: relative;
}

.memberNameDiv span {
    /* Adjust the styles for the text */
    margin-right: 4px;
    /* Add some space between the text and the arrow */
}

/* Adjust styles for the SVG icon */
.memberNameDiv svg {
    /* Adjust as needed */
    width: 20px;
    height: 20px;
}

.exportbtn {
    display: flex;
    padding: 8px 14px 8px 16px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 33px;
    background: #FF9058;
    cursor: pointer;
}

.exportbtn span {
    color: #202020;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    text-transform: uppercase;
}

/* Container for the checkbox and label */
.checkLabeldiv {
    display: flex;
    align-items: center;
    /* Center the items vertically */
}

/* Default style for the checkbox */
input[type="checkbox"] {
    /* Hide the default checkbox */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    /* Define the size of the checkbox */
    width: 20px;
    height: 20px;
    /* Add a black border */
    border: 1px solid #000;
    /* Add some margin to separate the checkbox from the label text */
    /* margin-right: 5px; */
    /* Set the cursor to pointer to indicate interactivity */
    cursor: pointer;
    /* Position relative for the ::before pseudo-element */
    /* position: relative; */
}

/* Style for the checkbox when it's checked */
input[type="checkbox"]:checked {
    /* Change the background color when checked */
    background-color: #FF9058;
}

/* Checkmark style */
input[type="checkbox"]::before {
    /* Content of the ::before pseudo-element */
    content: '';
    /* Position it in the center */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Hide it by default */
    opacity: 0;
    /* Define the size of the checkmark */
    width: 10px;
    height: 5px;
    /* Define the checkmark shape */
    border-left: 2px solid #FFF;
    border-bottom: 2px solid #FFF;
    /* Rotate the checkmark */
    transform: rotate(-45deg);
}

/* Checkmark style when the checkbox is checked */
input[type="checkbox"]:checked::before {
    /* Show the checkmark */
    opacity: 1;
    /* Center the checkmark within the checkbox */
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    /* Adjust rotation */
}

.seperateLine {
    width: 280px;
    height: 1px;
    background: #DDD;
}

.todayBtn {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    padding: 10px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid #D3D3D3;
    color: #202020;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
}

.todayBtn.selected {
    background: #DEDEDE;
    border: 1px solid transparent;
    /* Remove border when selected */
}

.dateFont {
    color: #878787;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.dateFramesContainer {
    display: flex;
    align-items: center;
    /* Align items vertically */
}

.dateFrame {
    display: flex;
    align-items: center;
    /* Align items vertically */
    width: calc(50% - 5px);
    /* Adjust width to fit two elements with space between them */
    padding: 8px;
    gap: 10px;
    border-radius: 6px;
}

.dashLine {
    margin: 4px;
    width: 5px;
    height: 2px;
    background-color: #202020;
}

.popupexport {
    width: 180px;
    height: 180px;
    position: relative;
    border-radius: 10px;
    border: 1px solid #F4F4F4;
    background: #FFF;
    box-shadow: 1px 1px 4px 0px rgba(89, 89, 89, 0.25);
    overflow-y: auto;
    padding: 16px;
}

.radioFrame {
    display: flex;
    height: 24px;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
}

.radioBtnText {
    color: #202020;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    /* 142.857% */
}

.printbtn {
    margin-left: 10px;
    display: flex;
    padding: 8px 14.16px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border-radius: 33px;
    border: 1px solid #000;
}

.printbtn span {
    color: #202020;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    text-transform: uppercase;
}

.nodatamain {
    display: flex;
    width: 1137px;
    padding: 48px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* border-radius: 0px 0px 20px 20px;
    border-right: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    border-left: 1px solid #E5E5E5; */
}

.emptyTitleFont {
    color: #202020;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
}

.emprtyDescFont {
    color: #202020;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}

.filterseperater {
    width: 280px;
    height: 1px;
    background: #DDD;
}