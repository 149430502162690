.rdw-dropdown-wrapper {
    z-index: 1000;
}
.full-container {
    background-color: white;
    color: black;
    /* Set text color to contrast with the black background */
    padding: 0px;
}
.editor-container {
     /*background-color: white;*/
     /*border-color: black;*/
     /*padding: 10px;*/
     /*border: 1px solid black;*/
     /*border-radius: 4px;*/
    border-radius: 16px;
    border: 1px solid #C1C1C1;
    display: flex;
    padding: 12px 18px;

    gap: 10px;
    align-self: stretch;
 }