.user-form__header{
    color: #202020;
    font-family: "Nunito Sans" !important;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 28px; /* 140% */
}

.side-drawer-field__label {
    color: #202020 !important;
    font-family: Raleway !important;
    font-size: 14px !important;
    font-style: normal ;
    font-weight: 600 !important;
    line-height: 20px; /* 142.857% */



}
.side-drawer-field__gap{
    margin-bottom:16px
}
.side-drawer-divider-gap{
    margin-top:24px;
    margin-bottom:24px;
}
.side-drawer-field{
    padding: 12px 18px !important;
    margin-top:8px;
    /*align-items: center;*/
    gap: 10px;
    align-self: stretch;
    border-radius: 34px !important;
    border: 1px solid #C1C1C1 !important;
}

.side-drawer-field-Textarea{
    padding: 12px 8px !important;
    margin-top:8px;
    /*align-items: center;*/
    gap: 10px;
    align-self: stretch;
    border-radius: 16px !important;
    border: 1px solid #C1C1C1 !important;
}
.side-drawer-field-default{

    padding: 10px 18px !important;

    margin-top:8px;
    /*align-items: center;*/
    gap: 10px;
    align-self: stretch;
    border-radius: 34px !important;
    border: 1px solid #C1C1C1 !important;
}

.side-drawer-field-date{
    padding: 6px 18px !important;
    margin-top:8px;
    /*align-items: center;*/
    gap: 10px;
    align-self: stretch;
    border-radius: 34px !important;
    border: 1px solid #C1C1C1 !important;
}
.side-drawer-field-dropdown{
    padding: 6px 18px !important;
    margin-top:8px;
    /*align-items: center;*/
    gap: 10px;
    align-self: stretch;
    border-radius: 34px !important;
    border: 1px solid #C1C1C1 !important;
}
.side-drawer-section__typo{
    color: #202020;
    font-family: Raleway !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 20px; /* 125% */
}


.side-drawer-grey-help{
    color: #686868 !important;
    font-family: Raleway !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 20px; /* 142.857% */
}
.sidebar-drawer-Addnew-lable{
    color: #D14600 !important;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 20px; /* 125% */
    cursor:pointer
}
.sidebar-drawer-Addnew-lable:hover{
    color: #D14600;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    text-decoration-line: underline;
}
.sidebar-drawer-file-upload{

    padding: 12px 12px 0px 12px !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid #C1C1C1;




}
.custom-file-input {
    padding: 10px 20px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px; /* Adjust font size as needed */
}
input > #file-upload-button{
    color:black !important;
    background:red !important
}