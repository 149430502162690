.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

html {
  overflow: hidden;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.personatexthide>span:nth-child(2) {
  display: none;
}

.wapmenu span.fui-MenuItem__content {
  padding: 0 0 0 5px;
}

.wapmenu span.fui-MenuItem__icon {
  margin: 0px 0 0px -30px;
  padding-right: 5px;
}

.wapmenu span.fui-MenuItem__checkmark {
  margin: 0px 5px 0 0px;
}
.public-DraftEditorPlaceholder-inner{
  min-width:400px;
}
.wapmenu div[name*="navigation menu"] {
  margin: 15px 0 0 0;
}

.wapleftnav .fui-DrawerInline {
  height: calc(100vh - 48px) !important;
}

.wapleftnav .fui-DrawerBody {
  padding: 10px 24px;
}
.datepicker-custom-wrap > div{
  width:404px
}
.f1nfxovz {
  width: unset !important;
}
.side-drawer-field-Textarea:focus-within {

  border:1px solid black !important;
}
.side-drawer-field:focus-visible {

  outline:1px solid black !important;
}
/* input[type=text]:focus-visible {
  border: 1px solid #000 !important;
} */
.side-drawer-field-Textarea:focus-within {
  border:1px solid black !important;
}
.editor-container:focus-visible{
  outline:1px solid black !important;
}


.prevent-selection {
  user-select: none;
  /* Prevent text selection */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}
.f1jc6hxc::after {
  border-bottom: none !important;
}
.wapleftnav .fui-DrawerHeaderTitle {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.card-add-button{
  display: flex;
  height: 40px;
  padding: 12px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 33px;
  border: 1.5px solid #202020;
  background: #FFFFFF;
  color: #202020;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.wallet-Update-button{
  display: flex;
  height: 44px;
  padding: 12px 13px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 33px;
  background: #FF9058;
  width:100%;
  border:none
}
.card-number-color{
  color: #202020;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}
.form-submit-btn {
  border-radius: 33px;
  background: #FF9058;

  height: 44px;
  padding: 12px 13px;
  border: 0px;
  width: 180px;
  color: #202020;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.form-submit-cancel {
  border-radius: 33px;
  background: #ffffff;
  color: #202020;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  height: 44px;
  padding: 12px 13px;
  /*border: 0px;*/
  width: 180px;
}

.rdw-editor-toolbar {
  padding: 6px 5px 0;
  border-radius: 12px;
  border: 1px solid #F1F1F1;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  -webkit-user-select: none;
  user-select: none;
}
.vertical-center {
  /*display: flex;*/
  /*justify-content: center;*/
  align-items: center;
}

.d-flex {
  display: flex !important;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: start;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: end;
}

.active-sidebar {
  border-radius: 28px !important;
  background: #333 !important;
  /* width: 207px !important; */
  height: 32px !important;
  flex-shrink: 0 !important;
}

.slick-slider{
  height:98%;
}
.slick-list{
  height:100%;
}
.example::-webkit-scrollbar {
  display: none;
}
.rsvps-header-typo {
  color: #202020;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}
.rsvps-cell-typo {
  color: #202020;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.f1t94bn6:hover {
  background: #FFF8F5 !important;
}
.side-drawer-field__label + .react-select__control .react-select__input--is-focused {
  border-color: black/* Your desired border color when focused */;
}
.list-row {
  width: 1137px;
  height: 60px;
  padding: 20px;
  gap: 10px;
  background: #f7f7f7 !important;
}

.margin-padding-none {
  margin: 0;
  padding: 0;
}

.table-layout {
  margin-top: 30px;
  border-radius: 20px 20px 20px 20px !important;
  border: 1px solid #E5E5E5;
  overflow: hidden;

}
.table-layout2 {
  margin-top: 30px;
  border-radius: 20px;
  border: 1px solid #E5E5E5;
  overflow-x: auto; /* Allows horizontal scrolling */
  display: block;
  max-width: 100%; /* Ensures container doesn’t exceed viewport width */
  padding-bottom: 10px; /* Optional: Adds space for scroll bar */
}
.table-layout3 {
  margin-top: 30px;
  border-radius: 20px;
  border: 1px solid #E5E5E5;
  display: block;
  overflow-x: auto;
}

.list-row2 {
  height: 60px;
  padding: 20px;
  gap: 10px;
  background: #f7f7f7 !important;
  white-space: nowrap; /* Prevents wrapping within header cells */
}

.table-layout2 table {
  min-width: 1000px; /* Ensures table doesn’t shrink below content width */
  width: 100%; /* Allows table to expand with container */
  display: block;
}

.myCustomCarousel > button {
  margin-left:-40px;

  content: '←';
  color:#BDBDBD;
  margin-right:-40px;
}
.slick-prev:before {
  content: url(./static/images/arrowLeft.svg);

}
.slick-next:before {
  content: url(./static/images/arrowRight.svg);

}

.overflow-DialogContent{
  overflow-y: scroll !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}
.overflow-DialogContent::-webkit-scrollbar {
  display: none !important;
}
.myCustomCarousel .slick-arrow {
  width: 48px;
  height: 48px;

  /*color :#BDBDBD;*/
  /*border:1px solid #BDBDBD;*/
  /*border-radius: 100%;*/
  /*background: #ffff !important;*/
}
.slick-prev {
  left: 3% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}




.table-row-fonts__primary {
  font-family: 'Nunito Sans' !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
}

.table-row-fonts__desc {
  font-family: 'Nunito Sans' !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.pointer {
  cursor: pointer;
}

.primary-btn {
  height: 44px;
  padding: 12px 24px 12px 20px !important;
  /* gap: 10px; */
  border-radius: 33px;
  background: #FF9058 !important;
  color: #202020 !important;
  font-family: Raleway !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.react-datepicker__input-container>input {
  min-width: 100%;
  height: 30px;
}

.r1jtohuq::after {
  border-bottom: none !important
}

.listHeaderText {
  color: #202020;
  font-family: "Raleway" !important;
  font-size: 14px;
  font-weight: 700 !important;
  line-height: 20px;
}

.btnBorderOnly {
  border: 1.2px solid #202020 !important
}

.yesBox {
  width: 65px;
  display: flex;
  padding: 4px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 22px;
  background: #CFEFDB;
  text-align: center;
  font-family: "Nunito Sans" !important;
  font-size: 14px;
  font-weight: 600;
}

.noBox {
  width: 65px;
  display: flex;
  padding: 4px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 22px;
  background: #FFD8D8;
  text-align: center;
  font-family: "Nunito Sans" !important;
  font-size: 14px;
  font-weight: 600;
}

.fbhmu18 {
  padding: 0px;
  padding-top: 0px !important;
  padding-right: 10px !important;
  padding-bottom: 0px !important;
  padding-left: 10px !important;
}

/* Dropdown sdk width issue */
.f1exfvgq {
  min-width: 218px !important;
}